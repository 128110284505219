import React, { useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import TimeLine from "./TimeLine";
import "./Home.css";
import rocket from "../../img/rocket.svg";
import tonone from "../../img/tonone.svg";
import tononelogo from "../../img/tononelogo.png";
import rocketitem from "../../img/rocketitem.png";

const Home = () => {
  const homeRef = useRef(null);
  const productRef = useRef(null);
  const productMobileRef = useRef(null);
  const roadmapRef = useRef(null);
  const supportRef = useRef(null);
  const footerRef = useRef(null);
  const scrollToRef = (ref, mobileRef) => () => {
    if (window.innerWidth >= 768) {
      // Giả sử 768px là breakpoint cho desktop
      ref.current.scrollIntoView({ behavior: "smooth" });
    } else {
      mobileRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <div className="bg-head">
        <div className="container mx-auto">
          <Header
            onHomeClick={scrollToRef(homeRef)}
            onProductClick={scrollToRef(productRef, productMobileRef)}
            onRoadmapClick={scrollToRef(roadmapRef)}
            onSupportClick={scrollToRef(supportRef)}
            onChannelClick={scrollToRef(footerRef)}
          />
          <div className="grid grid-cols-12 gap-4 mt-5">
            <div className="ld:col-span-7 md:col-span-8 col-span-12">
              <h1 className="hidden md:block md:text-5xl text-3xl font-semibold">
                Give me ONE TON
              </h1>
              <h1 className="hidden md:block md:text-5xl text-3xl font-semibold pb-5">
                and be a TONONE Millionaire
              </h1>
              <h1 className="md:hidden block md:text-5xl text-3xl font-semibold pb-5 text-center">
                Give me ONE TON and be a TONONE Millionaire
              </h1>
              <p className="mt-4 md:pb-8 md:text-start text-center">
                Free reward 10,000 TONONE monthly
              </p>
              <div className="flex md:justify-start justify-center">
                <button
                  onClick={() =>
                    window.open("https://t.me/tonone_app_bot", "_blank")
                  }
                  className="rounded-full bg-gradient-to-l from-[#004D74] to-[#0088CC] px-5 h-[44px] flex items-center mt-10 mb-6 md:mb-16 lg:mb-20 xl:mb-28"
                >
                  <img className="me-3" alt="rock" src={rocket}></img> GET
                  TONONE REWARD
                </button>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col items-center mt-10 gap-3 pb-10">
            <div className="bg-[#212121] bg-opacity-50 rounded-2xl p-5 height-tablet">
              <h1 className="md:text-2xl text-xl font-semibold">
                Simple and <br className="md:block hidden"></br> Accessible
              </h1>
              <p className="md:text-[14px] mt-3">
                Start mining TONONE tokens with just 1 TON. It's easy and
                straightforward!
              </p>
            </div>
            <div className="bg-[#212121] bg-opacity-50 rounded-2xl p-5 height-tablet">
              <h1 className="md:text-2xl text-xl font-semibold">
                Transparent <br className="md:block hidden"></br>and Fair
              </h1>
              <p className="text-[14px] mt-3">
                We do not accept over 1 TON in Reward App, ensuring equal
                opportunities for everyone.
              </p>
            </div>
            <div className="bg-[#212121] bg-opacity-50 rounded-2xl p-5 height-tablet">
              <h1 className="md:text-2xl text-xl font-semibold">
                Secure and <br className="md:block hidden"></br>Decentralized
              </h1>
              <p className="text-[14px] mt-3">
                Built on The Open Network (TON) technology, your transactions
                and earnings are protected.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={productRef}
        className="hidden container mx-auto md:flex flex-col justify-center items-center bg-[#FEFEFE]"
      >
        <div className="flex items-center justify-center pt-16 pb-8">
          <img alt="tonone" className="me-3" src={tonone}></img>
          <p className="text-[#7B8E97]">Products</p>
        </div>
        <h1 className="text-[#000] font-semibold text-5xl pb-14">
          Discover How TONONE Works
        </h1>
        <div className="bg-earn mb-6 rounded-3xl">
          <div className="grid grid-cols-12">
            <div className="col-span-1 xl:col-span-2"></div>
            <div className="col-span-6 xl:col-span-4 text-center md:py-10 lg:py-20">
              <p class="text-gradient bg-gradient-to-r from-[#5FC3E4] to-[#E55D87] bg-clip-text text-transparent md:mb-3 lg:mb-6">
                TELEGRAM BOT
              </p>
              <h1 className="md:text-3xl lg:text-4xl font-semibold md:mb-3 lg:mb-5">
                Start Earning TONONE Tokens Free
              </h1>
              <p className="text-[16px]">
                Join the fun and earn effortlessly – Send 1 TON to start
                receiving reward and enjoy equal opportunities for all users!
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() =>
                    window.open("https://t.me/tonone_app_bot", "_blank")
                  }
                  className="rounded-full bg-gradient-to-l from-[#004D74] to-[#0088CC] px-5 h-[44px] flex items-center md:mt-4 lg:mt-10"
                >
                  Start Earning via Telegram
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-12 grid grid-cols-12 mt-10">
          <div className="bg-[#FFF] rounded-2xl col-span-6 md:px-4 lg:px-10 shadow-2xl md:ms-3 xl:me-6">
            <div className="bg-item-first flex justify-center">
              <div className="w-[180px] py-10">
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="tonone"
                  src={tononelogo}
                ></img>
              </div>
            </div>
            <h1 className="font-semibold text-3xl text-[#000] py-6">
              X100 TONONE by 1 TON
            </h1>
            <p className="text-[#506169] pb-4">
              Transfer 1 TON to activate your earning session. You are not
              allowed to transfer over 1 TON.
            </p>
          </div>
          <div className="bg-[#FFF] rounded-2xl col-span-6 md:px-4 lg:px-10 shadow-2xl md:ms-3 xl:ms-6">
            <div className="bg-item-second flex justify-center">
              <div className="w-[180px] py-10">
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="tonone"
                  src={rocketitem}
                ></img>
              </div>
            </div>
            <h1 className="font-semibold text-3xl text-[#000] py-6">
              Earning Reward Free
            </h1>
            <p className="text-[#506169]">
              10,000 TOKEN/Month and Daily Reward are free to earn.
            </p>
          </div>
        </div>
      </div>
      <div
        ref={productMobileRef}
        className="md:hidden container mx-auto flex flex-col justify-center items-center bg-[#FEFEFE]"
      >
        <div className="flex items-center justify-center pt-10 pb-4">
          <img alt="tonone" className="me-3" src={tonone}></img>
          <h1 className="text-[#7B8E97] font-semibold">Products</h1>
        </div>
        <h1 className="text-[#313131] font-bold text-[32px] pb-10 text-center">
          Discover How TONONE Works
        </h1>
        <div className="bg-earn-mb mb-6 rounded-3xl">
          <div style={{ minHeight: "60vh" }} className="grid grid-cols-12">
            <div className="col-span-12 py-10" style={{ height: "30vh" }}></div>
            <div className="col-span-12 text-start py-10 ps-3 pe-2">
              <p className="text-gradient bg-gradient-to-r from-[#5FC3E4] to-[#E55D87] bg-clip-text text-transparent mb-3">
                TELEGRAM BOT
              </p>
              <h1 className="md:text-2xl text-xl font-semibold mb-3">
                Start Earning TONONE Tokens Free
              </h1>
              <p className="text-[14px]">
                Join the fun and earn effortlessly – Send 1 TON to start
                receiving reward and enjoy equal opportunities for all users!
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() =>
                    window.open("https://t.me/tonone_app_bot", "_blank")
                  }
                  className="rounded-full bg-gradient-to-l from-[#004D74] to-[#0088CC] px-8 h-[44px] flex items-center mt-6"
                >
                  Start Earning via Telegram
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-12 grid grid-cols-12 mt-10">
          <div className="bg-[#FFF] rounded-2xl col-span-12 px-6 pb-3 mb-6 md:px-4 lg:px-10 shadow-2xl md:ms-3 xl:me-6">
            <div className="bg-item-first flex justify-center">
              <div className="w-[180px] py-10">
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="tonone"
                  src={tononelogo}
                ></img>
              </div>
            </div>
            <h1 className="font-bold text-2xl text-[#000] py-6">
              X100 TONONE by 1 TON
            </h1>
            <p className="text-[#506169] pb-4">
              Transfer <span className="font-bold">1 TON</span> to activate your
              earning session. You are not allowed to transfer over 1 TON.
            </p>
          </div>
          <div className="bg-[#FFF] rounded-2xl col-span-12 px-6 pb-3 md:px-4 lg:px-10 shadow-2xl md:ms-3 xl:ms-6">
            <div className="bg-item-second flex justify-center">
              <div className="w-[180px] py-10">
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="tonone"
                  src={rocketitem}
                ></img>
              </div>
            </div>
            <h1 className="font-bold text-2xl text-[#000] py-6">
              Earning Reward Free
            </h1>
            <p className="text-[#506169]">
              <span className="font-bold">10,000 TOKEN/Month</span> and{" "}
              <span className="font-bold">Daily Reward</span> are free to earn.
            </p>
          </div>
        </div>
      </div>
      <div ref={roadmapRef} className="container mx-auto relative my-3">
        <TimeLine />
      </div>
      <div ref={supportRef} className="container mx-auto pb-16">
        <div className="bg-head bg-head-bot pb-6 rounded-3xl">
          <div className="grid grid-cols-12">
            <div className="lg:col-span-2"></div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12 text-center py-10 md:py-24">
              <p class="text-gradient bg-gradient-to-r from-[#5FC3E4] to-[#E55D87] bg-clip-text text-transparent mb-6">
                SUPPORT
              </p>
              <h1 className="text-3xl md:text-4xl font-semibold md:mb-5">
                Need Help? We’re Here for You!
              </h1>
              <div className="flex justify-center">
                <button
                  onClick={() =>
                    window.open("https://t.me/tonone_store", "_blank")
                  }
                  className="rounded-full bg-gradient-to-l from-[#004D74] to-[#0088CC] px-5 h-[44px] flex items-center mt-10"
                >
                  Ask me anything
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={footerRef} className="bg-[#0A0A0A]">
        <div className="container mx-auto">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
