// import React from "react";
// import roadmap from "../../img/bgTimeLine.svg"; // Update with your SVG path

// const TimeLine = () => {
//   const events = [
//     {
//       year: "2024",
//       title: "TONONE Reward Earning App",
//       description: "TONONE Community\n1 TON Hub",
//     },
//     {
//       year: "2025",
//       title: "ONETON STORE",
//       description: "ONE STORE (EU only)\nPublic Sale",
//     },
//     {
//       year: "2026",
//       title: "ONE Asset Assessment",
//       description: "ONE Incubator",
//     },
//     {
//       year: "2027",
//       title: "ONE Asset Management",
//       description: "ONEVERSE",
//     },
//     {
//       year: "2028",
//       title: "ONE Venture Capital",
//       description: "",
//     },
//     {
//       year: "2029",
//       title: "ONE NETWORK",
//       description: "",
//     },
//   ];

//   return (
//     <div className="p-10">
//       <img src={roadmap}></img>
//     </div>
//   );
// };

// export default TimeLine;
import React, { useState, useEffect, useRef } from "react";
import "./TimeLine.css";
import tonone from "../../img/tonone.svg";
import roadmap from "../../img/roadmap.svg";
import roadmapMb from "../../img/roadmap-mb.png";

const TimeLine = () => {
  return (
    <div className="mb-10">
      <div className="container mx-auto flex flex-col justify-center items-start bg-[#FEFEFE]">
        <div className="flex items-center justify-center pt-16 pb-8">
          <img alt="tonone" className="me-3" src={tonone}></img>
          <p className="text-[#7B8E97]">Roadmap</p>
        </div>
        <h1 className="text-[#313131] font-semibold text-3xl md:text-5xl pb-14">
          Our journey and Future Plans
        </h1>
      </div>
      <div className="mb-3">
        <img
          className="md:block hidden"
          alt="roadmap"
          src={roadmap}
          style={{ width: "100%" }}
        ></img>
        <img
          className="block md:hidden"
          alt="roadmap"
          src={roadmapMb}
          style={{ width: "100%" }}
        ></img>
      </div>
      <div className="hidden md:flex flex-row items-center justify-center">
        {/* <button className="rounded-full bg-[#7B8E97] px-5 h-[44px] flex items-center mt-10">
          Litepaper
        </button>
        <button className="rounded-full bg-[#7B8E97] px-5 h-[44px] flex items-center mt-10 mx-8">
          Tokenomic
        </button> */}
        <button
          onClick={() => window.open("https://t.me/tonone_app_bot", "_blank")}
          className="rounded-full bg-gradient-to-l from-[#004D74] to-[#0088CC] px-5 h-[44px] flex items-center mt-10"
        >
          Start Earning via Telegram
        </button>
      </div>
    </div>
  );
};

export default TimeLine;
