import React, { useState } from "react";
import logo from "../../img/logo.svg";
import { FaBars } from "react-icons/fa";
import { HiXMark } from "react-icons/hi2";

const Header = ({
  onHomeClick,
  onProductClick,
  onRoadmapClick,
  onSupportClick,
  onChannelClick,
}) => {
  const [active, setActive] = useState(false);

  return (
    <div className="flex justify-between pb-14 md:pb-20 xl:pb-32 items-center pt-10 xl:pt-20">
      <img className="logo-img" alt="logo" src={logo}></img>
      <div className="rounded-full p-3 bg-[#212121] bg-opacity-50 lg:flex hidden">
        <div
          // onClick={onHomeClick}
          style={{ cursor: "pointer" }}
          className="py-2 px-4"
        >
          Home
        </div>
        <div
          onClick={onProductClick}
          style={{ cursor: "pointer" }}
          className="py-2 px-4"
        >
          Product
        </div>
        <div
          onClick={onRoadmapClick}
          style={{ cursor: "pointer" }}
          className="py-2 px-4"
        >
          Roadmap
        </div>
        <div
          onClick={onSupportClick}
          style={{ cursor: "pointer" }}
          className="py-2 px-4"
        >
          Support
        </div>
        <div
          onClick={onChannelClick}
          style={{ cursor: "pointer" }}
          className="py-2 px-4"
        >
          Channel
        </div>
      </div>
      <div className="flex items-center">
        <button
          onClick={() => window.open("https://t.me/tonone_app_bot", "_blank")}
          className="hidden lg:block rounded-full bg-gradient-to-l from-[#004D74] to-[#0088CC] px-3 lg:px-5 h-[44px]"
        >
          GET REWARD
        </button>
        <div onClick={() => setActive(true)} className="lg:hidden block ms-3">
          <FaBars />
        </div>
      </div>
      <div className={`header-mobi py-4 ${active ? "active" : ""}`}>
        <div
          onClick={() => setActive(false)}
          className="w-full flex flex-row-reverse text-[22px] p-3"
        >
          <HiXMark />
        </div>
        <div className="py-2 px-4">Home</div>
        <div onClick={onProductClick} className="py-2 px-4">
          Product
        </div>
        <div onClick={onRoadmapClick} className="py-2 px-4">
          Roadmap
        </div>
        <div onClick={onSupportClick} className="py-2 px-4">
          Support
        </div>
        <div onClick={onChannelClick} className="py-2 px-4">
          Channel
        </div>
      </div>
    </div>
  );
};

export default Header;
