import React from "react";
import logo from "../../img/logo.svg";
import tele from "../../img/tele.svg";
import vk from "../../img/vk.svg";
import x from "../../img/x.svg";
import insta from "../../img/insta.svg";
import dot from "../../img/dot.svg";
import logoMb from "../../img/logo-mb.svg";
import teleMb from "../../img/tele-mb.svg";
import vkMb from "../../img/vk-mb.svg";
import xMb from "../../img/x-md.svg";
import instaMb from "../../img/insta-mb.svg";
import dotMb from "../../img/dot-mb.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="hidden md:block text-center space-y-10 py-6">
        <div className="space-y-2">
          <p className="text-[14px] text-[#ccc]">STAY CONNECTED WITH TONONE</p>
          <h1 className="md:text-[50px] text-[16px] font-semibold md:font-bold">
            Join the TONONE Community Today!
          </h1>
          <p className="text-[18px] text-[#ccc]">
            Stay updated with the latest news, updates, and events by following
            us on our social media channels.
          </p>
        </div>
        <button
          className="rounded-full bg-gradient-to-l from-[#004D74] to-[#0088CC] px-12 md:px-5 h-[44px]"
          onClick={() => window.open("https://t.me/tonone_app_bot", "_blank")}
        >
          Connect to Reward App
        </button>
        <div className="hidden md:flex justify-between items-center">
          <div className="flex">
            <img className="me-10" alt="logo" src={logo}></img>
            <div className="flex items-center">
              <Link className="mx-2" to={"https://t.me/tonone_store"}>
                <img alt="tele" src={tele}></img>
              </Link>

              <Link className="mx-2" to={"https://x.com/TONONE_Official"}>
                <img alt="x" src={x}></img>
              </Link>
            </div>
          </div>
          <p className="text-[14px] text-[#ccc]">
            © 2024 TONONE. All rights reserved.
          </p>
        </div>
        <div className="md:hidden flex justify-between items-center">
          <img alt="logo" src={logoMb}></img>
          <div className="flex items-center">
            <Link className="mx-2" to={"https://t.me/tonone_store"}>
              <img alt="tele" src={teleMb}></img>
            </Link>

            <Link className="mx-2" to={"https://x.com/TONONE_Official"}>
              <img alt="x" src={xMb}></img>
            </Link>
          </div>
        </div>
      </div>
      <div className="md:hidden block text-center space-y-10 py-6">
        <div className="space-y-2">
          <p className="text-[14px] text-[#ccc]">STAY CONNECTED WITH TONONE</p>
          <h1 className="md:text-[50px] text-[16px] font-semibold md:font-bold">
            Join the TONONE Community Today!
          </h1>
          <p className="text-[18px] text-[#ccc]">
            Stay updated with the latest news, updates, and events by following
            us on our social media channels.
          </p>
        </div>
        <button
          className="rounded-full bg-gradient-to-l from-[#004D74] to-[#0088CC] px-12 md:px-5 h-[44px]"
          onClick={() => window.open("https://t.me/tonone_app_bot", "_blank")}
        >
          Connect to Reward App
        </button>

        <div className="hidden md:flex justify-between items-center">
          <img alt="logo" src={logo}></img>
          <div className="flex items-center">
            <Link className="mx-2" to={"https://t.me/tonone_store"}>
              <img alt="tele" src={tele}></img>
            </Link>

            <Link className="mx-2" to={"https://x.com/TONONE_Official"}>
              <img alt="x" src={x}></img>
            </Link>
          </div>
        </div>
        <div className="md:hidden flex flex-col justify-between items-center space-y-4">
          <img alt="logo" src={logoMb}></img>
          <div className="flex items-center">
            <Link className="mx-1" to={"https://t.me/tonone_store"}>
              <img alt="tele" src={teleMb}></img>
            </Link>

            <Link className="mx-1" to={"https://x.com/TONONE_Official"}>
              <img alt="x" src={xMb}></img>
            </Link>
          </div>
          <p className="text-[14px] text-[#ccc]">
            © 2024 TONONE. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
